$dropdown-color: #1E1E2D;
$dropdown-icon-size: 20px;

manager-wrapper-dropdown {
  border-radius: inherit;
}

.dropdown-menu {
  inset: 8px 0px auto auto !important;
  min-width: 250px;
  max-width: 500px;
  min-height: 150px;
  max-height: 500px;
  border-radius: 10px;
  margin: 0;
  padding: 0;

  .title {
    background-image:url('/assets/img/menu-header-bg.jpg');
    height: 60px;
    border-radius: 10px 10px 0 0;
  }
  .content {
    max-height: 440px;
    overflow: scroll;
  }
  .dropdown-item {
    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }
    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }
  }

  &.actions {
    inset: 0px 0px auto auto !important;
    min-width: unset;
    min-height: unset;

    .dropdown-item {
      padding: 10px 15px;
    }
  }
}