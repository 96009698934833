@import "../var";

.menu-c1 {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    width: max-content;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $height_header - 24px;
      margin: 24px 7px 0 0;
      cursor: pointer;
      background: hsla(0,0%,76.5%,.4);
      border-radius: 8px 8px 0 0;
      border: none;
      padding: 8px 12px;
      font-size: 14px;
      color: $white;

      &:not(.active):hover {
        height: $height_header - 18px;
        margin-top: 18px;
      }

      &.active {
        height: $height_header - 12px;
        margin-top: 12px;
        background-color: #EEF0F8;
        border-bottom: #EEF0F8;
        font-size: 15px;
        color: $text-primary;

        .menu-icon {
          font-size: 20px;
          color: $text-primary;
        }
      }
    }
  }

  .menu-icon {
    padding-right: 10px;
    color: $white;

    i {
      font-size: 18px;
      color: inherit;
    }

    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
  }
}

.menu-c2 {
  position: sticky;
  top: $height_header;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  height: $height_c2;
  margin: 0;
  padding: 0 10px;
  background: #EEF0F8;
  box-shadow: none;
  z-index: 3;

  &.menu-c2-offcanvas {
    top: 0px;
    background: $white;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      border-color: transparent;
      border-radius: 8px;
      margin: 0 10px 0 0;
      padding: 7px 13px;
      box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
      font-size: 14.5px;
      color: #a1a5b7;

      &:hover {
        background-color: #F5F8FA;
      }

      &.active {
        color: $blue;
        background-color: #F5F8FA;
      }
    }
  }

  .menu-icon {
    padding-right: 6px;

    i {
      color: inherit;
    }
  }
}

.menu-c3 {
  position: sticky;
  top: $height_c2;
  left: 0;
  right: 0;
  display: block;
  height: $height_c3;
  margin: 0 0 10px 0;
  padding: 0 15px;
  background: #EEF0F8;
  box-shadow: none;
  z-index: 2;

  &.Menus2x {
    top: $height_header + $height_c2;
  }

  ul {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #d2d2d2;

    li {
      list-style: none;
      background: transparent;
      margin-right: 20px;
      padding: 10px 0;
      font-size: 13px;

      &:hover {
        padding: 11px 0 9px 0;
        border-bottom: 2px solid $blue;
      }

      &.active {
        color: $blue;
        font-weight: bold;
        padding: 11px 0 9px 0;
        border-bottom: 2px solid $blue;
      }
    }
  }

  .menu-icon {
    padding-right: 5px;

    i {
      color: inherit;
    }
  }
}