$menu-a1-icon-color: #494B74;
$menu-a1-color-hover: #0DCAF0;
$menu-a1-color-background-active: #1B1B28;

#aside {
  position: fixed;
  width: $width_aside;
  overflow: hidden;
  background: $color-main;

  #aside_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $height_header;

    &.aside-logo {
      background-color: $color-main;
    }

    .brand-logo {
      .site-name {
        position: absolute;
        top: 42px;
        width: calc(100% - 5px);
        display: block;
        text-align: center;
        align-items: center;
        color: #888;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  #aside_menu_wrapper {
    min-height: calc(100vh - #{$height_header});
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu-a1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      .menu-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        overflow: hidden;
        transition: height 0.40s ease-out;

        .level {
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 44px;

          .menu-content {
            display: flex;
            align-items: center;
            justify-content: start;
            flex: 1;

            .menu-icon {
              width: 38px;
              text-align: center;

              i {
                font-size: 18px;
                color: $menu-a1-icon-color;
                transition: transform 0.30s ease-out;
              }
            }
            .menu-title {
              color: $grey;
            }
          }
          .menu-arrow {
            display: flex;
            align-items: center;
            justify-content: end;

            i {
              transition: transform 0.30s ease-in;

              &.rotate {
                transform: rotate(90deg);
                transition: transform 0.30s ease-in;
              }
            }
          }
        }

        &:hover > .level {
          background: $menu-a1-color-background-active;
          .menu-content {
            .menu-icon {
              i {
                color: $menu-a1-color-hover;
              }
            }
            .menu-title {
              color: $menu-a1-color-hover;
            }
          }

        }
        &.active > .level {
          background: $menu-a1-color-background-active;
          .menu-content {
            .menu-icon {
              i {
                color: $color-primary;
              }
            }
            .menu-title {
              color: $white;
            }
          }
        }
      }

      &.level1 {
        font-size: 13px;
        padding: 15px 0 10px 0;

        li {
          .level {
            padding: 10px 20px 10px 25px;
          }
        }
      }
      &.level2 {
        font-size: 13px;

        li {
          .level {
            padding: 10px 10px 10px 40px;
          }
        }
      }
    }

    #aside-footer {
      margin: 0;
      padding: 5px 0;
      font-size: 12px;
      text-align: center;
      color: #3F4254;
    }
  }

  @media screen and (max-width: 992px) {
    width: 400px;

    &:not(.aside-mobile) {
      display: none;
    }
  }
}

#menu-mobile.btn {
  border: none;
  box-shadow: none;
}

.menu-a1 {


}

.menu-a2 {
  position: fixed;
  top: 0;
  right: 0;
  height: $height_header;
  margin-bottom: -$height_header;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 99;
  padding: 0 10px 0 0;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
  }

  .menu-a2-user {
    width: 350px;

    ul {
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: $dropdown-color;
        padding: 12px 15px;
        cursor: pointer;

        &.active {
          color: $text-primary;
        }

        &:hover {
          color: $text-primary;
        }
      }
    }

    .menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;
      width: 30px;

      i {
        font-size: 18px;
        color: inherit;
      }
    }
  }
}