@import "./1_header";
@import "./2_menus_a";
@import "./4_menus_c";
@import "./6_wrapper_dropdown";
@import "./6_wrapper_modal";
@import "./6_wrapper_offcanvas";
@import "./6_wrapper_page";
@import "./7_action_toolbar";
@import "./9_footer";
@import "./10_loader";
@import "./error";
@import "./pagination";
@import "./bubble";
@import "./breadcrumb";
@import "./context_menu";
@import "./splash_screen";
@import "./toast";

:host {
  height: 100%;
  margin: 0;
}

// LAYOUT
.container-fluid {
  padding: 0 15px;
}

// FADE
.fade-container {
  position: relative;
}
.fade-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &.fade-item-c1 {
    top: -31px;
  }
  &.fade-item-c2 {
    top: $height_c2;
  }
  &.fade-item-c3 {
    top: $height_c3;
  }
}

// MATERIAL
.cdk-overlay-container {
  z-index: 1050;
}

// SYMBOL
.symbol {
  img {
    //max-height: 80px;
    border-radius: 20px;
  }
}